<template>
    <div class="app-container meeting-Reservation">
        <div class="filter-line">
            <span>
                <el-button type="primary" icon="el-icon-plus" @click="addReservation">新增预定</el-button>
                <el-button @click="exportHandler">{{ this.$t("commons.export") }}</el-button>
                <el-button @click="getTableList" icon="el-icon-refresh-right">{{ this.$t("commons.refresh")
                    }}</el-button>
            </span>
            <div class="header-filter-button" @click="dataset.filterLine.show = !dataset.filterLine.show"
                :class="dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'">
                <i class="iconfont icon-loudoutu"></i>筛选
            </div>
        </div>
        <div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler">
                <template v-slot:projectIdSlot="row">
                    <span> {{ row.datas.projectName }}</span>
                </template>
            </finalTable>
        </div>
        <!-- dialog -->
        <el-dialog :visible.sync="suggestionObj.dialogVisible" :before-close="handleClose" width="900px"
            :destroy-on-close="true" :close-on-click-modal="false">
            <template slot="title">
                <span>{{ suggestionObj.dialogTitle }}</span>
            </template>
            <meetingDialog v-if="suggestionObj.dialogVisible" ref="meetingDialog"
                :dialogStatus="suggestionObj.dialogStatus" :id="suggestionObj.id" @close="handleClose" :suggestionObj = "suggestionObj"></meetingDialog>
        </el-dialog>
    </div>
</template>

<script>
// import {
//     getMeetingReservationList,
//     cancelMeeting,
//     ConferenceListExport
// } from "@/api/ruge/gsPark/customerService/MeetingRoom";

import {
    getMeetingReservationList,
    cancelMeeting,
    ConferenceListExport
} from "@/api/ruge/lego/meetingRoom/meetingRoom"
import { getProjectList } from "@/api/business/base/tenant/map";

import finalTable from "@/components/FinalTable";
import meetingDialog from "./meetingDialog.vue"
export default {
    name: "meetingComponent",
    components: {
        finalTable,
        meetingDialog
    },
    data() {
        return {
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                header: [
                    { prop: "projectId", label: "项目名称", width: "" },
                    { prop: "source", label: "会议来源", width: "" },
                    { prop: "scheduledDate", label: "预定日期", width: "200" },
                    { prop: "meetingTime", label: "会议时间", width: "240" },
                    { prop: "conferenceName", label: "会议室名称", width: "" },
                    { prop: "contacts", label: "预约人", width: "" },
                    { prop: "company", label: "预定单位", width: "" },
                    { prop: "phone", label: "预定手机号", width: "" },
                    { prop: "serviceStatus", label: "状态", width: "" },
                    { prop: "cancelBy", label: "取消操作人", width: "" },
                    { prop: "operation", label: "操作", width: "110" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {
                    projectId: {
                        type: "selector",
                        label: "项目名称",
                        value: "",
                        placeholder: "请选择项目名称",
                        actionType: "goSearch",
                        optionList: [],
                    },
                    source: {
                        type: "selector",
                        label: "会议来源",
                        value: "",
                        placeholder: "请选择",
                        actionType: "goSearch",
                        optionList: [
                            {
                                value: "1",
                                label: "小程序预定",
                            },
                            {
                                value: 2,
                                label: "后台客户预定",
                            },
                            {
                                value: 3,
                                label: "OA预定",
                            },
                        ],
                    },
                    scheduledDate: {
                        type: "input",
                        label: "预定日期",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    meetingTime: {
                        type: "input",
                        label: "会议时间",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    conferenceName: {
                        type: "input",
                        label: "会议室名称",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    contacts: {
                        type: "input",
                        label: "预约人",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    company: {
                        type: "input",
                        label: "预定单位",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    phone: {
                        type: "input",
                        label: "手机号码",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    serviceStatus: {
                        type: "selector",
                        label: "状态",
                        value: "",
                        placeholder: "请选择",
                        actionType: "goSearch",
                        optionList: [
                            {
                                value: "1",
                                label: "待审核",
                            },
                            {
                                value: 2,
                                label: "已取消",
                            },
                            {
                                value: 3,
                                label: "已预定",
                            },
                        ],
                    },
                    cancelBy: {
                        type: "input",
                        label: "取消操作人",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    // operation: {
                    //     type: "button",
                    //     filterCount: "",
                    //     actionType: "clickEvent",
                    //     eventName: "showMoreFilters",
                    //     label: "更多筛选",
                    // },
                },
                // 表格内容配置
                detailConfig: {
                    projectId: {
                        type: 'slot',
                        slotName: 'projectIdSlot'
                    },
                    operation: {
                        type: "icon",
                        iconList: [
                            {
                                actionType: "iconClick",
                                eventName: "view",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "查看",
                            },
                            {
                                actionType: "iconStatus",
                                eventName: "view",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-edit",
                                tooltips: "取消预定",
                                showProp: "destinedStatus",
                                showValue: 1,
                            },
                        ],
                    }
                },
                // 高级搜索配置
                advanceFilterConfig: {
                    // scheduledDate: {
                    //     type: "dateRange",
                    //     pickerType: "date",
                    //     label: "报单时间",
                    //     value: [],
                    // },
                },
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            suggestionObj: {
                tableLoading: false,
                dialogVisible: false,
                dialogTitle: "新增会议预定",
                dialogStatus: "add",
                listQuery: {
                    current: 1,
                    rowCount: 10,
                    startDestinedTime: null,
                    endDestinedTime: null,
                }
            },
        }
    },
    created() {
        this.getTableList()
        this.getProjectList()
    },
    methods: {
        // 初始化数据
        getTableList() {
            this.suggestionObj.tableLoading = true;
            const params = { ...this.suggestionObj.listQuery };
            for (let pn in params) {
                !params[pn] && params[pn] !== 0 && (params[pn] = null);
            }
            getMeetingReservationList(params)
                .then((res) => {
                    console.log(res, '列表数据');
                    res.data.rows.forEach((item) => {
                        if (item.source == 3) {
                            item.source = 'OA预定'
                        }
                        if (item.source == 2) {
                            item.source = '后台客户预定'
                        }
                        if (item.source == 1) {
                            item.source = '小程序预定'
                        }
                        if (item.serviceStatus == 1) {
                            item.serviceStatus = '待审核'
                        }
                        if (item.serviceStatus == 2) {
                            item.serviceStatus = '已取消'
                        }
                        if (item.serviceStatus == 3) {
                            item.serviceStatus = '已预定'
                        }
                    });
                    this.dataset.pageVO.total = res.data.total;
                    this.dataset.tableData = res.data.rows;
                    console.log(this.dataset.tableData, 'this.dataset.tableData');
                })
                .finally(() => {
                    this.suggestionObj.tableLoading = false;
                });
        },
        getProjectList() {
            getProjectList().then(res => {
                let compantList = []
                res.forEach(item => {
                    compantList.push({
                        label: item.projectName,
                        value: item.projectId
                    })
                })
                this.dataset.searchLineConfig.projectId.optionList = compantList
                this.suggestionObj.projectList = compantList
            })
        },
        // table组件页面事件配置
        tableEventHandler(datas) {
            if (datas.type === "goSearch") {
                // this.dealTime(datas);
                this.suggestionObj.listQuery = {
                    ...this.suggestionObj.listQuery,
                    ...datas.params,
                };
                this.suggestionObj.listQuery.current = 1;
                this.dataset.pageVO.current = 1;
                this.getTableList();
            } else if (datas.type === "paginationChange") {
                this.suggestionObj.listQuery.current = datas.params.current.page;
                this.suggestionObj.listQuery.rowCount = datas.params.current.limit;
                this.getTableList();
            } else if (datas.type === "iconStatus") {
                this.singlecancelHandler(datas.row);
            } else if (datas.type === "iconClick") {
                switch (datas.eventName) {
                    case "view":
                        this.suggestionObj.id = datas.row.id;
                        this.suggestionObj.dialogStatus = "view";
                        this.suggestionObj.dialogVisible = true;
                        this.suggestionObj.dialogTitle = "查看会议预定";
                }
            }
        },
        // 预定日期查询
        // dealTime(datas) {
        //     if (datas.params.scheduledDate && datas.params.scheduledDate.length > 0) {
        //         this.suggestionObj.listQuery.startDestinedTime = datas.params.scheduledDate[0]
        //             ? dateFormat(datas.params.scheduledDate[0], "YYYY-MM-DD")
        //             : null;
        //         this.suggestionObj.listQuery.endDestinedTime = datas.params.scheduledDate[1]
        //             ? dateFormat(
        //                 new Date(datas.params.scheduledDate[1]).getTime() +
        //                 1000 * 60 * 60 * 24 - 1,
        //                 "YYYY-MM-DD"
        //             )
        //             : null;
        //         delete datas.params.scheduledDate;
        //     }
        //     return datas;
        // },
        addReservation() {
            // console.log(111);
            this.suggestionObj.id = null;
            this.suggestionObj.dialogStatus = "add";
            this.suggestionObj.dialogVisible = true;
            this.suggestionObj.dialogTitle = "新增会议预定";
        },
        singlecancelHandler(datas) {
            console.log(datas);
            this.$confirm(
                "取消后将不可恢复，确定取消么？",
                this.$t("commons.warning"),
                {
                    confirmButtonText: this.$t("commons.confirm"),
                    cancelButtonText: this.$t("commons.cancel"),
                    type: "warning",
                }
            )
                .then(() => {
                    cancelMeeting({
                        // source: 'web',
                        // applyTypeName: '会议室取消',
                        id: datas.id,
                    }).then(res => {
                        console.log(res);
                        if (res.code == 200) {
                            this.$message({
                                message: "取消成功",
                                type: 'success'
                            });
                            this.getTableList();
                        }
                    })
                })
                .catch((error) => {
                    console.log(`未删除，原因 => ${error}`);
                });
        },
        handleClose(freshFlag) {
            this.suggestionObj.dialogVisible = false;
            freshFlag && this.getTableList();
        },
        // 导出
        exportHandler() {
            ConferenceListExport(this.suggestionObj.listQuery).then((taskId) => {
                this.$message({
                    type: "success",
                    message: this.$t("message.operationSuccess"),
                });
                let exportObj = {
                    taskId,
                    taskName: "会议列表",
                    taskStatus: 0,
                    rootPath: "customerlegoPath",
                };
                this.$store.dispatch("PushExportNotice", exportObj);
            });
        },
    },
    watch: {
        suggestionObj: {
            handler(val) {
                this.$refs.finalTableRef.loadingToggle(val.tableLoading);
            },
            deep: true,
        },
    }
}
</script>

<style lang="less" scoped>
.meeting-Reservation {
    .filter-line {
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            height: 36px;
        }

        .icon-loudoutu {
            font-size: 12px;
        }

        .header-filter-button {
            cursor: pointer;
            width: 84px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 3px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }

        .filter-button-open {
            color: #409eff;
            background: #ecf5ff;
            border: 1px solid #c6e2ff;
        }

        .filter-button-close {
            color: #2a4158;
            background: #ffffff;
            border: 1px solid #e7e8eb;
        }
    }
}
</style>